import * as React from "react";
import { Filter, DateInput, TextInput, List, Datagrid, TextField, DateField, EmailField } from 'react-admin';
import { CurrencyField } from '../../../atoms'

const PurchaseFilter = props => (
  <Filter {...props}>
    <TextInput source='token' resettable autoComplete='off' />
    <TextInput source='parkingToken' resettable autoComplete='off' />
    <TextInput source='email' resettable autoComplete='off' />
    <DateInput source="validFrom||$gte" resettable />
    <DateInput source="validFrom||$lte" resettable />
  </Filter>
)

const list = props => (
  <List filters={<PurchaseFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="status" />
      <TextField source="token" />
      <TextField source="accountToken" />
      <EmailField source="email" />
      <TextField source="productToken" />
      <CurrencyField source="cost" />
      <TextField source="parkingToken" />
      <TextField source="numberPlate" />
      <DateField source="validFrom" showTime={true} />
      <DateField source="validTo" showTime={true} />
    </Datagrid>
  </List>
);

export default list