import * as React from "react";
import { List, Datagrid, TextField, EmailField } from 'react-admin';

const list = props => (
  <List exporter={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <EmailField source="email" />
    </Datagrid>
  </List>
)

export default list