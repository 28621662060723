import React from 'react';
import PropTypes from 'prop-types';
import { priceFormatter } from '../../../utils'

const CurrencyField = ({ source, record = {} }) => {
  const value = record[source] ? priceFormatter(record[source] / 100) : '--'
  return (
    <span class="MuiTypography-root MuiTypography-body2" >{value}</span>
  )
}

CurrencyField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CurrencyField.defaultProps = {
  addLabel: true,
};

export default CurrencyField;