import * as React from "react";
import { Datagrid, Show, SimpleShowLayout, TextField, NumberField, ArrayField } from 'react-admin';
import { CoordinatesField, CurrencyField } from '../../../atoms'

const ParkingTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>;
};

const show = props => (
  <Show title={<ParkingTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <CoordinatesField source="latitude" />
      <ArrayField source="products">
        <Datagrid>
          <TextField source="id" />
          <NumberField source="days" />
          <CurrencyField source="price" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default show