export default {
  generalError: 'Ha ocurrido un error',
  resources: {
    admins: {
      name: 'Administradores',
      fields: {
        password: 'Contraseña',
      },
    },
    parkings: {
      name: 'Parkings',
      fields: {
        id: 'ID (token EOS)',
        name: 'Nombre',
        latitude: 'Coordenadas',
        products: 'Productos',
        address: 'Dirección',
        days: 'Nº días',
        price: 'Precio',
      },
      actions: {
        refreshCache: 'Recargar parkings'
      }
    },
    purchases: {
      name: 'Compras',
      fields: {
        id: 'ID',
        status: 'Estado',
        token: 'ID (token EOS)',
        accountToken: 'ID Usuario (token EOS)',
        name: 'Nombre',
        email: 'Email',
        productToken: 'ID Producto (token EOS)',
        productDays: 'Nº días',
        productPrice: 'Precio',
        cost: 'Importe',
        parkingToken: 'ID Parking (token EOS)',
        parkingName: 'Nombre del parking',
        numberPlate: 'Matrícula',
        validFrom: 'Entrada',
        validTo: 'Salida',
        createdAt: 'Creado',
        activatedAt: 'Activado',
        'validFrom||$gte': 'Entrada desde',
        'validFrom||$lte': 'Entrada hasta',
      },
    },
  },
}
