import React from 'react';
import PropTypes from 'prop-types';

const CoordinatesField = ({ source, record = {} }) => {
  const href = `https://maps.google.com/maps?q=${record['latitude']},${record['longitude']}`
  return (
    <span class="MuiTypography-root MuiTypography-body2">
      <a rel="noopener noreferrer" target="_blank" href={href}>
        {record['latitude']}, {record['longitude']}
      </a>
    </span >
  )
}
CoordinatesField.defaultProps = { label: 'Coordinates' };

CoordinatesField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CoordinatesField.defaultProps = {
  addLabel: true,
};

export default CoordinatesField;