import _ from 'lodash'
import * as api from '../../api'

const tokenItem = 'token'

const authProvider = {
  login: params => login(params),
  logout: params => logout(params),
  checkAuth: params => checkAuth(params),
  checkError: error => checkError(error),
  getPermissions: () => Promise.resolve(),
}

const login = async ({ username, password }) => {
  const loginRes = await api.login({ username, password })
  const token = _.get(loginRes, 'data.jwt', null)

  localStorage.setItem(tokenItem, token)
  return { token }
}

const logout = async () => {
  localStorage.removeItem(tokenItem)
}

const checkAuth = () => {
  const token = localStorage.getItem(tokenItem)
  return token ? Promise.resolve() : Promise.reject()
}

const checkError = error => {
  const status = error.status;
  if (status === 401 || status === 403) {
    localStorage.removeItem(tokenItem);
    return Promise.reject();
  }
  return Promise.resolve()
}

export const getAuthToken = () => localStorage.getItem(tokenItem)

export const isAuth = () => (localStorage.getItem(tokenItem) ? true : false)

export default authProvider
