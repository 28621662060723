export default {
  generalError: 'An error has occurred',
  resources: {
    admins: {
      name: 'Administrators',
      fields: {
        password: 'Password',
      },
    },
    parkings: {
      name: 'Parkings',
      fields: {
        id: 'ID (token EOS)',
        name: 'Name',
        latitude: 'Coordinates',
        products: 'Products',
        address: 'Address',
        days: 'Nº of days',
        price: 'Price',
      },
      actions: {
        refreshCache: 'Refresh cache'
      }
    },
    purchases: {
      name: 'Purchases',
      fields: {
        id: 'ID',
        status: 'Status',
        token: 'ID (token EOS)',
        accountToken: 'Account ID (token EOS)',
        name: 'Name',
        email: 'Email',
        productToken: 'Product ID (token EOS)',
        cost: 'Cost',
        parkingToken: 'Parking ID (token EOS)',
        numberPlate: 'Number plate',
        validFrom: 'Valid from',
        validTo: 'Valid to',
        createdAt: 'Created',
        activatedAt: 'Activated',
      },
    },
  },
}
