import * as React from "react";
import {
  sanitizeListRestProps,
  TopToolbar,
  Filter,
  TextInput,
  List,
  Datagrid,
  Button,
  TextField
} from 'react-admin';
import Refresh from '@material-ui/icons/Refresh';
import { refreshParkingCache } from "../../../../api";

const ParkingFilter = props => (
  <Filter {...props}>
    <TextInput source='name' resettable autoComplete='off' />
  </Filter>
)

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button
        onClick={() => { refreshParkingCache() }}
        label="resources.parkings.actions.refreshCache"
      >
        <Refresh />
      </Button>
    </TopToolbar>
  );
};

const list = props => (
  <List
    actions={<ListActions />}
    filters={<ParkingFilter />}
    bulkActionButtons={false}
    exporter={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
    </Datagrid>
  </List>
);

export default list