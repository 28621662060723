import * as React from "react";
import { Create, TextInput, SimpleForm, PasswordInput, email } from 'react-admin';

const validateAdminCreation = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = ['The email is required'];
  }
  if (!values.password) {
    errors.password = ['The password is required'];
  } else if (values.password.length < 8) {
    errors.password = ['Password should have 8 characters at least'];
  }
  return errors
};

const validateEmail = email();

const create = props => (
  <Create {...props}>
    <SimpleForm validate={validateAdminCreation}>
      <TextInput type="email" source="email" validate={validateEmail} />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
)

export default create