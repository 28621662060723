import enMessages from 'ra-language-english'
import esMessages from '@blackbox-vision/ra-language-spanish'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import domainMessages from '../../assets/i18n'
import { resolveBrowserLocale } from 'react-admin'

const messages = {
  es: { ...esMessages, ...domainMessages.es },
  en: { ...enMessages, ...domainMessages.en },
}

const locale = resolveBrowserLocale()

const i18nProvider = polyglotI18nProvider(locale => messages[locale] || messages.es, locale)

export default i18nProvider
