import authProvider from '../providers/authProvider'
//import dataProvider from '../providers/dataProvider'
import i18nProvider from '../providers/i18nProvider'
import layout from '../components/system/layout'
import { BASE_URL, API_ENTRYPOINT } from './api';
import theme from './theme'
import crudProvider from '@fusionworks/ra-data-nest-crud'
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  const token = localStorage.getItem('token')
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}


const dataProvider = crudProvider(BASE_URL + API_ENTRYPOINT, httpClient);

export default {
  authProvider,
  dataProvider,
  i18nProvider,
  layout,
  theme
}
