import * as React from "react";
import { DateField, Show, SimpleShowLayout, TextField, EmailField } from 'react-admin';
import { CurrencyField } from '../../../atoms'


export const show = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="token" />
      <TextField source="accountToken" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="productToken" />
      <TextField source="productDays" />
      <CurrencyField source="productPrice" />
      <CurrencyField source="cost" />
      <TextField source="parkingToken" />
      <TextField source="parkingName" />
      <TextField source="numberPlate" />
      <DateField source="validFrom" showTime={true} />
      <DateField source="validTo" showTime={true} />
      <DateField source="createdAt" showTime={true} />
      <DateField source="activatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export default show