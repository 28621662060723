import * as React from "react";
import { Admin, Resource } from 'react-admin';
import admin from './config/admin'
import { Admins, Parkings, Purchases } from './components/pages'

const App = () => (
  <Admin {...admin}>
    <Resource name="parkings" {...Parkings} />
    <Resource name="purchases" {...Purchases} />
    <Resource name="admins" {...Admins} />
  </Admin>
);

export default App;