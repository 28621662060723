import { configureAxiosDefaults, instance as axios } from './utils'
import { BASE_URL, API_ENTRYPOINT } from '../config/api'
import { getAuthToken } from '../providers/authProvider'

export function configureToken(token) {
  configureAxiosDefaults('Authorization', token ? `Bearer ${token}` : null)
}

export function configureLanguage(locale) {
  configureAxiosDefaults('Accept-Language', locale)
}

// AUTH
export const login = ({ username, password }) => {
  const url = `${BASE_URL}${API_ENTRYPOINT}/login`
  return axios.post(url, { username, password })
}

export const refreshParkingCache = () => {
  const url = `${BASE_URL}${API_ENTRYPOINT}/parkings/refresh-cache`
  const token = getAuthToken()

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(res => {
        resolve(true)
      })
      .catch(error => reject(error))
  })
}

export default axios
