import { createMuiTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import colors from '../assets/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.darkOrange,
    },
    secondary: {
      main: colors.orange,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {},
  overrides: {
    MuiDrawer: {
      root: {
        background: colors.orange,
        minHeight: '100vh',
      },
    },
    RaLink: {
      link: {
        textDecoration: 'underline',
      },
    },
    RaMenuItemLink: {
      root: {
        color: colors.darkOrange,
      },
      active: {
        color: colors.darkOrange,
      },
      icon: {
        color: colors.darkOrange,
      },
    },
  },
})

export default theme
