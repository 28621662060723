import * as React from "react";
import { Edit, TextInput, SimpleForm, PasswordInput, email } from 'react-admin';

const validateAdminEdit = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = ['The email is required'];
  }
  if (values.password && values.password.length < 8) {
    errors.password = ['Password should have 8 characters at least'];
  }

  return errors
};

const validateEmail = email();

const edit = props => (
  <Edit {...props}>
    <SimpleForm validate={validateAdminEdit}>
      <TextInput type="email" source="email" validate={validateEmail} />
      <PasswordInput source="password" />
    </SimpleForm>
  </Edit>
)

export default edit