import * as React from "react";
import { Show, SimpleShowLayout, TextField, EmailField } from 'react-admin';

const AdminTitle = ({ record }) => {
  return <span>{record ? `${record.email}` : ''}</span>;
};

const show = props => (
  <Show title={<AdminTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
    </SimpleShowLayout>
  </Show>
);

export default show